var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "market_page detail_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [
        _vm._v("Marketplace Detail"),
      ]),
      _c("section", { staticClass: "overview_sec" }, [
        _c("div", {
          staticClass: "bg_el",
          style: `background-image: url(${_vm.artworkBackground})`,
        }),
        _c("div", { staticClass: "set_inner" }, [
          _c("header", { staticClass: "sec_head" }, [
            _c("div", { staticClass: "thumb_area" }, [
              _vm.editionInfos.mimeType === "image/jpeg"
                ? _c("img", {
                    attrs: {
                      srcset: `${_vm.source_small} 590w, ${_vm.source_medium} 1920w, ${_vm.source}`,
                      src: _vm.source,
                      width: "890",
                      height: "500",
                      alt: _vm.artworkAlt ? _vm.artworkAlt : "",
                    },
                  })
                : _c(
                    "video",
                    {
                      staticStyle: { width: "auto" },
                      attrs: {
                        loop: "",
                        autoplay: "",
                        playsinline: "",
                        muted: "",
                        src: _vm.source,
                      },
                      domProps: { muted: true },
                    },
                    [
                      _vm._v(
                        " Sorry, your browser doesn't support embedded videos. "
                      ),
                    ]
                  ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn type_ic btn_like",
                class: {
                  is_active: _vm.likeStatus === true,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.likeThis(_vm.editionInfos)
                  },
                },
              },
              [
                _vm.likeStatus === true
                  ? _c("IcLike", {
                      ref: "icLike",
                      attrs: {
                        status: _vm.likeStatus === true,
                        "icon-color": "#ff521d",
                      },
                    })
                  : _c("IcLike", {
                      ref: "icLike",
                      attrs: { "icon-color": "#fff" },
                    }),
              ],
              1
            ),
          ]),
          Object.keys(_vm.editionInfos).length > 0
            ? _c("div", { staticClass: "sec_body" }, [
                _c(
                  "div",
                  { staticClass: "ttl_wrap" },
                  [
                    _c("div", { staticClass: "ttl_area" }, [
                      _c(
                        "h3",
                        {
                          class: [
                            _vm.totalEdition > 1 &&
                            _vm.isMultiEdition &&
                            _vm.editionId != 0
                              ? "ttl clickable textUnderLine"
                              : "ttl",
                          ],
                          on: { click: _vm.goMultiEdition },
                        },
                        [_vm._v(" " + _vm._s(_vm.editionInfos.title) + " ")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "profile_info is_art clickable",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _vm.artistAvatarImg
                          ? _c("span", {
                              staticClass: "profile",
                              style: `background-image: url(${_vm.artistAvatarImg})`,
                            })
                          : _c("IcProfile", { staticClass: "profile" }),
                        _c("IcRing", {
                          staticClass: "ic_ring",
                          on: {
                            click: function ($event) {
                              return _vm.goArtistProfile()
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "name",
                            on: {
                              click: function ($event) {
                                return _vm.goArtistProfile()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.artistName))]
                        ),
                      ],
                      1
                    ),
                    _vm.editionId != 0
                      ? _c("p", { staticClass: "edit" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editionInfos.totalCount > 1
                                  ? `${_vm.editionInfos.editionId} of ${_vm.editionInfos.totalCount} Editions`
                                  : `${_vm.editionInfos.editionId} edition`
                              ) +
                              " "
                          ),
                        ])
                      : _c("p", { staticClass: "edit" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editionInfos.totalEdition > 1
                                  ? `${_vm.editionInfos.totalEdition} Editions`
                                  : _vm.editionInfos.editionId === 0
                                  ? "1 edition"
                                  : `${_vm.editionInfos.editionId} edition`
                              ) +
                              " "
                          ),
                        ]),
                    _vm.editionInfos.bcNwTp == "10" &&
                    _vm.hdrWallet.walletYn === "N" &&
                    _vm.isMob &&
                    (_vm.openBlade || _vm.openWallypto)
                      ? [
                          _c(
                            "div",
                            { staticClass: "input_area select drop_ui" },
                            [
                              _c(
                                "div",
                                { staticClass: "input_box drop_box" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "drop_btn has_arw",
                                      class: { is_active: _vm.walletSelect },
                                      attrs: {
                                        role: "combobox",
                                        "aria-haspopup": "listbox",
                                        "aria-controls": "dropWalletOpts",
                                        "aria-activedescendant": `wallet_op_${_vm.walletFocusOpt}`,
                                        "aria-expanded": _vm.walletSelect
                                          ? "true"
                                          : "false",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.walletSelect = !_vm.walletSelect
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.walletOpt.label) + " "
                                      ),
                                      _vm.walletOpt.value === "WLT001"
                                        ? _c("b", { staticClass: "badge" }, [
                                            _vm._v("Popular"),
                                          ])
                                        : _vm._e(),
                                      _c("ArwSelect", {
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      _vm.walletSelect
                                        ? _c(
                                            "ul",
                                            {
                                              staticClass: "drop_op_list",
                                              attrs: { role: "listbox" },
                                            },
                                            _vm._l(
                                              _vm.walletOptData,
                                              function (opt, i) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: "wallet_op_" + i,
                                                    class: [
                                                      {
                                                        is_focus:
                                                          _vm.walletFocusOpt ===
                                                          i,
                                                      },
                                                      {
                                                        is_active:
                                                          _vm.walletOpt
                                                            .value ===
                                                          opt.value,
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "wallet_op_" + i,
                                                      role: "option",
                                                      "aria-selected": "false",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.walletOtp(
                                                          opt,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(opt.label)),
                                                    ]),
                                                    opt.value === "WLT001"
                                                      ? _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "badge",
                                                          },
                                                          [_vm._v("Popular")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", { staticClass: "buy_wrap float_el" }, [
                  _c("div", { staticClass: "buy_group" }, [
                    _c("div", { staticClass: "label_list_group" }, [
                      _vm.editionId != 0
                        ? _c("dl", { staticClass: "label_list" }, [
                            _vm._m(0),
                            _c("dd", [
                              _vm.contractAddress
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tooltip_ui",
                                      on: { click: _vm.goContract },
                                    },
                                    [
                                      _c("b", { staticClass: "clickable" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.contractAddress.length > 15
                                              ? _vm.contractAddress.substr(
                                                  0,
                                                  8
                                                ) +
                                                  "..." +
                                                  _vm.contractAddress.substr(
                                                    _vm.contractAddress.length -
                                                      3,
                                                    _vm.contractAddress.length
                                                  )
                                              : _vm.contractAddress
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          ref: "contractAddress",
                                          staticClass: "tooltip_area",
                                          attrs: { role: "tooltip" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.contractAddress) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass: "btn type_ic btn_copy",
                                  on: { click: _vm.copy },
                                },
                                [_c("IcCopy")],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.editionId > 0
                        ? _c("dl", { staticClass: "label_list own" }, [
                            _c("dt", [_vm._v("Owned by")]),
                            _c("dd", [
                              _c(
                                "div",
                                {
                                  staticClass: "profile_info",
                                  class: {
                                    clickable:
                                      _vm.editionInfos.ownerName.indexOf(
                                        "0x"
                                      ) == -1,
                                  },
                                },
                                [
                                  _vm.editionInfos.ownerAvatarInfo &&
                                  _vm.editionInfos.ownerAvatarInfo.length
                                    ? _c("span", {
                                        staticClass: "profile",
                                        style: `background-image: url(${_vm.editionInfos.ownerAvatarInfo[0].resUrl})`,
                                      })
                                    : _c("IcProfile", {
                                        staticClass: "profile",
                                        attrs: {
                                          width: 32,
                                          height: 32,
                                          "stroke-width": 0.9,
                                        },
                                      }),
                                  _vm.editionInfos.bcNwtp == "10"
                                    ? _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.goUserProfile()
                                            },
                                          },
                                        },
                                        [
                                          _c("b", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(_vm.editionInfos.ownerName)
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          class: {
                                            ethName:
                                              _vm.editionInfos.ownerName.indexOf(
                                                "0x"
                                              ) !== -1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.goUserProfile(
                                                _vm.editionInfos.ownerName.indexOf(
                                                  "0x"
                                                ) !== -1
                                                  ? true
                                                  : false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.editionInfos.ownerName.indexOf(
                                                  "0x"
                                                ) !== -1
                                                  ? _vm.editionInfos.ownerName
                                                      .length > 15
                                                    ? _vm.editionInfos.ownerName.substr(
                                                        0,
                                                        8
                                                      ) +
                                                      "..." +
                                                      _vm.editionInfos.ownerName.substr(
                                                        _vm.editionInfos
                                                          .ownerName.length - 3,
                                                        _vm.editionInfos
                                                          .ownerName.length
                                                      )
                                                    : _vm.editionInfos.ownerName
                                                  : _vm.editionInfos.ownerName
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("dl", { staticClass: "label_list" }, [
                        _c("dt", [_vm._v("Last Sale")]),
                        _c("dd", [
                          _c("p", { staticClass: "price" }, [
                            _vm.editionInfos.bcNwTp == "10"
                              ? _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("setNum")(
                                        _vm.editionInfos.currentPrice
                                      )
                                    ) + " USDC"
                                  ),
                                ])
                              : _vm.editionInfos.bcNwTp == "20" &&
                                _vm.editionInfos.currentPrice == 0 &&
                                _vm.editionInfos.currentPriceToEthUsd == 0
                              ? _c("b", [_vm._v("-")])
                              : _vm.editionInfos.bcNwTp == "20" &&
                                _vm.editionInfos.currentPrice == 0 &&
                                _vm.editionInfos.currentPriceToEthUsd > 0
                              ? _c("b", [
                                  _vm._v(" < 0.001"),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("Ξ"),
                                  ]),
                                  _c("span", { staticClass: "dollars" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("crtpyAbbreviate")(
                                            _vm.editionInfos
                                              .currentPriceToEthUsd
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                ])
                              : _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.editionInfos.currentPrice) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("Ξ"),
                                  ]),
                                  _c("span", { staticClass: "dollars" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("crtpyAbbreviate")(
                                            _vm.editionInfos
                                              .currentPriceToEthUsd
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                          ]),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm._f("UsaFormat")(
                                  _vm._f("GmtToTz")(
                                    _vm.editionInfos.lastSaleDate
                                  )
                                )
                              ) + " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("dl", { staticClass: "label_list" }, [
                        _c("dt", [_vm._v("Blockchains")]),
                        _c("dd", [
                          _c(
                            "p",
                            { staticClass: "blockchains" },
                            [
                              _vm.editionInfos.bcNwTp == "10"
                                ? [_c("IcHederaS"), _c("b", [_vm._v("Hedera")])]
                                : [_c("IcEthS"), _c("b", [_vm._v("Ethereum")])],
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _vm.showPriceTax && _vm.editionInfos.totalEdition > 1
                        ? _c("dl", { staticClass: "label_list" }, [
                            _c("dt", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.editionInfos.bcNwTp == "10"
                                      ? "Lowest Price"
                                      : "Price"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("dd", [
                              _c("p", { staticClass: "price" }, [
                                _vm.editionInfos.bcNwTp == "10"
                                  ? _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("setNum")(
                                              _vm.editionInfos.lowestPrice
                                            )
                                          ) +
                                          " USDC "
                                      ),
                                    ])
                                  : _c("b", [
                                      _vm._v(
                                        " " + _vm._s(_vm.editionInfos.price)
                                      ),
                                      _c("span", { staticClass: "unit" }, [
                                        _vm._v("Ξ"),
                                      ]),
                                      _c("span", { staticClass: "dollars" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm._f("crtpyAbbreviate")(
                                                _vm.editionInfos.priceToEthUsd
                                              )
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.showPriceTax
                        ? _c("dl", { staticClass: "label_list" }, [
                            _vm._m(1),
                            _c("dd", {}, [
                              _c(
                                "p",
                                { staticClass: "price" },
                                [
                                  _vm.accessToken && _vm.zipCodeCheck
                                    ? [
                                        _vm.editionInfos.bcNwTp == "10"
                                          ? _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("setNum")(_vm.taxOnly)
                                                ) + " USDC"
                                              ),
                                            ])
                                          : _c("b", [
                                              _vm._v(_vm._s(_vm.taxOnly)),
                                              _c(
                                                "span",
                                                { staticClass: "unit" },
                                                [_vm._v("Ξ")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "dollars" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm._f(
                                                          "crtpyAbbreviate"
                                                        )(
                                                          _vm.editionInfos
                                                            .taxToEthUsd
                                                        )
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                      ]
                                    : _vm.accessToken && !_vm.zipCodeCheck
                                    ? [
                                        _c("b", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "c_point",
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: _vm.directUpdateInfo,
                                              },
                                            },
                                            [_vm._v("Update your info")]
                                          ),
                                          _vm._v(
                                            " to check the sales tax estimate."
                                          ),
                                        ]),
                                      ]
                                    : !_vm.accessToken
                                    ? [
                                        _c("b", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "c_point",
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loginPopup()
                                                },
                                              },
                                            },
                                            [_vm._v("Sign in")]
                                          ),
                                          _vm._v(
                                            " to check the sales tax estimate."
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn strong w_xs h_m",
                        attrs: { disabled: "" },
                      },
                      [_vm._v("Not for Sale")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "desc_wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "desc_group more_ui",
                      class: { is_more: _vm.showMoreDesc },
                    },
                    [
                      _c("h4", { staticClass: "ttl type_underL" }, [
                        _vm._v("Description"),
                      ]),
                      _vm.editionInfos.editionContent
                        ? _c("p", { staticClass: "desc" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.editionInfos.editionContent) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn type_txt has_arw dv_mob toggle_desc",
                          on: {
                            click: function ($event) {
                              _vm.showMoreDesc = !_vm.showMoreDesc
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.showMoreDesc ? "Close" : "More") +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "tag_group" }, [
                    _c("h4", { staticClass: "ttl type_underL" }, [
                      _vm._v("Tag"),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "tag_list clearFix" },
                      _vm._l(_vm.editionInfos.keywords, function (tag, i) {
                        return _c(
                          "li",
                          { key: "tag_" + i, staticClass: "tag_node" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "tag_item",
                                on: {
                                  click: function ($event) {
                                    return _vm.goTagSearch(tag)
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(tag))])]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "set_inner" }, [
        _c("section", { staticClass: "detail_sec detail_info_sec" }, [
          _c("header", { ref: "tabs", staticClass: "sec_head" }, [
            _c("div", { staticClass: "tab_wrap" }, [
              _c(
                "div",
                { staticClass: "tab_list", attrs: { role: "tablist" } },
                [
                  _vm._l(_vm.infoTabData, function (tab) {
                    return [
                      _vm.editionId > 0 && tab.id == "status"
                        ? _c(
                            "div",
                            {
                              key: tab.id,
                              staticClass: "tab_node",
                              attrs: { role: "presentation" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tab_item",
                                  class: {
                                    is_active: _vm.currentTab === tab.id,
                                  },
                                  attrs: {
                                    id: "tab_" + tab.id,
                                    role: "tab",
                                    "aria-controls": tab.id + "_panel",
                                    "aria-selected":
                                      tab.id === _vm.currentTab ? true : false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setTab(tab.id)
                                    },
                                  },
                                },
                                [
                                  tab.icon === "time"
                                    ? _c("IcTime", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "items"
                                    ? _c("IcItems", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "eye"
                                    ? _c("IcEye", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "video"
                                    ? _c("IcVideo", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(tab.name))]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.editionId === 0 && tab.id == "editions"
                        ? _c(
                            "div",
                            {
                              key: tab.id,
                              staticClass: "tab_node",
                              attrs: { role: "presentation" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tab_item",
                                  class: {
                                    is_active: _vm.currentTab === tab.id,
                                  },
                                  attrs: {
                                    id: "tab_" + tab.id,
                                    role: "tab",
                                    "aria-controls": tab.id + "_panel",
                                    "aria-selected":
                                      tab.id === _vm.currentTab ? true : false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setTab(tab.id)
                                    },
                                  },
                                },
                                [
                                  tab.icon === "time"
                                    ? _c("IcTime", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "items"
                                    ? _c("IcItems", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "eye"
                                    ? _c("IcEye", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "video"
                                    ? _c("IcVideo", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(tab.name))]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      tab.id == "film" || tab.id == "docent"
                        ? _c(
                            "div",
                            {
                              key: tab.id,
                              staticClass: "tab_node",
                              attrs: { role: "presentation" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tab_item",
                                  class: {
                                    is_active: _vm.currentTab === tab.id,
                                  },
                                  attrs: {
                                    id: "tab_" + tab.id,
                                    role: "tab",
                                    "aria-controls": tab.id + "_panel",
                                    "aria-selected":
                                      tab.id === _vm.currentTab ? true : false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setTab(tab.id)
                                    },
                                  },
                                },
                                [
                                  tab.icon === "time"
                                    ? _c("IcTime", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "items"
                                    ? _c("IcItems", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "eye"
                                    ? _c("IcEye", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  tab.icon === "video"
                                    ? _c("IcVideo", {
                                        attrs: { "stroke-color": "#fff" },
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(tab.name))]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "sec_body tab_panel",
              attrs: {
                id: _vm.currentTab + "_panel",
                role: "tabpanel",
                "aria-labelledby": "tab_" + _vm.currentTab,
              },
            },
            [
              _vm.editionId > 0 && _vm.currentTab === "status"
                ? [
                    _c("div", { staticClass: "detail_info_wrap" }, [
                      _c("div", { staticClass: "detail_info_inner" }, [
                        _c("header", { staticClass: "detail_info_head" }, [
                          _c("ul", { staticClass: "status_list_head" }, [
                            _c("li", { staticClass: "history_info" }, [
                              _vm._v("Status"),
                            ]),
                            _c("li", { staticClass: "date_info" }, [
                              _vm._v("Date and Time"),
                            ]),
                            _c("li", { staticClass: "market_info" }, [
                              _vm.editionInfos.bcNwTp == "20"
                                ? _c("span", [_vm._v("Market")])
                                : _vm._e(),
                            ]),
                            _c("li", { staticClass: "price_info" }, [
                              _vm._v("Price"),
                            ]),
                          ]),
                        ]),
                        _vm.isStatusLoading
                          ? _c(
                              "div",
                              { staticClass: "detail_info_body" },
                              [_c("LoadingSpinner")],
                              1
                            )
                          : _c("div", { staticClass: "detail_info_body" }, [
                              _vm.statusInfos && _vm.statusInfos.length > 0
                                ? _c(
                                    "ul",
                                    { staticClass: "status_list" },
                                    _vm._l(
                                      _vm.statusInfos,
                                      function (status, i) {
                                        return _c(
                                          "li",
                                          {
                                            key: "status_" + i,
                                            staticClass: "status_node",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "status_item",
                                                attrs: { href: "#" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "history_info",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(status.action) +
                                                          " "
                                                      ),
                                                      status.actionType ==
                                                      "normal"
                                                        ? _c(
                                                            "em",
                                                            {
                                                              staticClass:
                                                                "name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    status.activityName
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "date_info" },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "date" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "UsaFormat"
                                                              )(
                                                                _vm._f(
                                                                  "GmtToTz"
                                                                )(status.date)
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "market_info",
                                                  },
                                                  [
                                                    status.bcNwTp == "20"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              status.otherMarketYn ==
                                                                "Y"
                                                                ? "Other Market"
                                                                : "LG Art Lab"
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "price_info" },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "price" },
                                                      [
                                                        status.bcNwTp == "10"
                                                          ? _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "setNum"
                                                                  )(
                                                                    status.price
                                                                  )
                                                                ) + " USDC"
                                                              ),
                                                            ])
                                                          : status.bcNwTp ==
                                                              "20" &&
                                                            status.action.indexOf(
                                                              "Stopped"
                                                            ) !== -1
                                                          ? _c("b", [
                                                              _vm._v("-"),
                                                            ])
                                                          : status.bcNwTp ==
                                                              "20" &&
                                                            status.price == 0 &&
                                                            status.priceToEthUsd ==
                                                              0
                                                          ? _c("b", [
                                                              _vm._v("-"),
                                                            ])
                                                          : status.bcNwTp ==
                                                              "20" &&
                                                            status.price == 0 &&
                                                            status.priceToEthUsd >
                                                              0
                                                          ? _c("b", [
                                                              _vm._v(
                                                                " < 0.001 "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "unit",
                                                                },
                                                                [_vm._v("Ξ")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "dollars",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "crtpyAbbreviate"
                                                                        )(
                                                                          status.priceToEthUsd
                                                                        )
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  status.price
                                                                )
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "unit",
                                                                },
                                                                [_vm._v("Ξ")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "dollars",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "crtpyAbbreviate"
                                                                        )(
                                                                          status.priceToEthUsd
                                                                        )
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "guide_ui no_data" },
                                    [
                                      _c("div", { staticClass: "img_area" }, [
                                        _c("picture", [
                                          _c("source", {
                                            attrs: {
                                              media: "(max-width: 600px)",
                                              type: "image/png",
                                              srcset: `${require("@/assets/img/img_mob_illust_nodata_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nodata_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nodata.png")}`,
                                            },
                                          }),
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/img_illust_nodata.png"),
                                              alt: "nodata",
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          " This artwork currently doesn't have any sales activity. "
                                        ),
                                      ]),
                                    ]
                                  ),
                            ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.editionId === 0 && _vm.currentTab === "editions"
                ? [
                    _c(
                      "div",
                      { staticClass: "list_wrap tbl_wrap detail_info_wrap" },
                      [
                        _c("header", {
                          ref: "ulRef",
                          staticClass:
                            "list_head tbl_head detail_info_head taR",
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "list_body tbl_body detail_info_body",
                          },
                          [
                            _c("div", { staticClass: "tbl_area" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "tbl",
                                  class: {
                                    isLoading: _vm.isStatusLoading,
                                  },
                                },
                                [
                                  _c("caption", { staticClass: "is_blind" }, [
                                    _vm._v(" Editions "),
                                  ]),
                                  _vm._m(2),
                                  _vm.isStatusLoading
                                    ? _c(
                                        "tbody",
                                        { attrs: { colspan: "6" } },
                                        [_c("LoadingSpinner")],
                                        1
                                      )
                                    : _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.editionItems,
                                          function (item, i) {
                                            return _c("tr", { key: item.id }, [
                                              _c(
                                                "td",
                                                { staticClass: "item" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "thumb_area clickable",
                                                    },
                                                    [
                                                      item.mimeType ===
                                                      "video/mp4"
                                                        ? _c("img", {
                                                            attrs: {
                                                              srcset:
                                                                !item.videoThumbInfos
                                                                  ? null
                                                                  : `${item.videoThumbInfos[0].resUrl} 2x, ${item.videoThumbInfos[1].resUrl}`,
                                                              src: !item.videoThumbInfos
                                                                ? null
                                                                : `${item.videoThumbInfos[1].resUrl}`,
                                                              width: 179,
                                                              height: 100,
                                                              alt: item.editionId,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.goArtworkDetail(
                                                                  item.artworkId,
                                                                  item.editionId
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _c("img", {
                                                            attrs: {
                                                              srcset:
                                                                !item.videoThumbInfos
                                                                  ? null
                                                                  : `${item.imageInfos[0].resUrl} 2x, ${item.imageInfos[1].resUrl}`,
                                                              src: !item.imageInfos
                                                                ? null
                                                                : `${item.imageInfos[1].resUrl}`,
                                                              width: 179,
                                                              height: 100,
                                                              alt: item.editionId,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.goArtworkDetail(
                                                                  item.artworkId,
                                                                  item.editionId
                                                                )
                                                              },
                                                            },
                                                          }),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn type_ic btn_like",
                                                          class: {
                                                            is_active:
                                                              _vm.editionItems[
                                                                i
                                                              ].favorites ==
                                                              "Y",
                                                          },
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.likeThisEdition(
                                                                item.artworkId,
                                                                item.editionId,
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.editionItems[i]
                                                            .favorites == "N"
                                                            ? _c("IcLike", {
                                                                ref: "icLikeEdition",
                                                                refInFor: true,
                                                                attrs: {
                                                                  status:
                                                                    _vm
                                                                      .editionItems[
                                                                      i
                                                                    ]
                                                                      .favorites ==
                                                                    "N",
                                                                  "icon-color":
                                                                    "#fff",
                                                                },
                                                              })
                                                            : _c("IcLike", {
                                                                ref: "icLikeEdition",
                                                                refInFor: true,
                                                                attrs: {
                                                                  "icon-color":
                                                                    "#ff521d",
                                                                },
                                                              }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label_info edit",
                                                      attrs: {
                                                        "data-label":
                                                          "Editions",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "#" +
                                                            _vm._s(
                                                              item.editionId
                                                            )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " / " +
                                                          _vm._s(
                                                            _vm.editionInfos
                                                              .totalCount
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "own label_info",
                                                  attrs: {
                                                    "data-label": "Owned by",
                                                  },
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "price label_info",
                                                  attrs: {
                                                    "data-label": "Last Sale",
                                                  },
                                                },
                                                [
                                                  item.bcNwTp == "10"
                                                    ? _c("b", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("setNum")(
                                                                item.lastSalePrice
                                                              )
                                                            )
                                                        ),
                                                        _c("br"),
                                                        _vm._v("USDC "),
                                                      ])
                                                    : _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.lastSalePrice
                                                          )
                                                        ),
                                                        _vm._m(3, true),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "dollars",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "crtpyAbbreviate"
                                                                  )(
                                                                    item.lastSaleToEthUsd
                                                                  )
                                                                ) +
                                                                ")"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "dv_mob",
                                                      staticStyle: {
                                                        "white-space":
                                                          "pre-line",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("UsaFormat")(
                                                            _vm._f("GmtToTz")(
                                                              item.lastSaleDate
                                                            ),
                                                            "newLine"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "time dv_pc",
                                                  attrs: {
                                                    title: item.ownrWlltAcnt,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "white-space":
                                                          "pre-line",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("UsaFormat")(
                                                              _vm._f("GmtToTz")(
                                                                item.lastSaleDate
                                                              ),
                                                              "newLine"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "current label_info",
                                                  attrs: {
                                                    "data-label":
                                                      "Current Price",
                                                  },
                                                },
                                                [
                                                  item.bcNwTp == "10"
                                                    ? _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("setNum")(
                                                              item.price
                                                            )
                                                          ) + " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v("USDC"),
                                                      ])
                                                    : item.bcNwTp == "20" &&
                                                      item.price == 0 &&
                                                      item.priceToEthUsd == 0
                                                    ? _c("b", [_vm._v(" -")])
                                                    : item.bcNwTp == "20" &&
                                                      item.salesMethod &&
                                                      item.salesMethod === 1
                                                    ? _c("b", [_vm._v(" -")])
                                                    : item.bcNwTp == "20" &&
                                                      item.price == 0 &&
                                                      item.priceToEthUsd > 0
                                                    ? _c("b", [
                                                        _vm._v(" < 0.001 "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "unit",
                                                          },
                                                          [_vm._v("Ξ")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "dollars",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "crtpyAbbreviate"
                                                                  )(
                                                                    item.priceToEthUsd
                                                                  )
                                                                ) +
                                                                ")"
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _c("b", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.price) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "unit",
                                                          },
                                                          [_vm._v("Ξ")]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "dollars",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "(" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "crtpyAbbreviate"
                                                                  )(
                                                                    item.priceToEthUsd
                                                                  )
                                                                ) +
                                                                ")"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                              _vm._m(4, true),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c("InfinityScroll", {
                          on: {
                            scroll: function ($event) {
                              return _vm.getStatusOrEditions(true)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.currentTab === "docent"
                ? [
                    _c("DocentThumbSlide", {
                      attrs: { "item-data": _vm.docentThumbItems },
                      on: { setCurrent: _vm.setDocentCurrent },
                    }),
                    _c("div", { staticClass: "viewer_wrap type_img" }, [
                      _c("div", { staticClass: "img_area" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.docentThumbImage,
                            width: 1220,
                            alt: "docentThumbnail",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "desc_area" }, [
                        _c("p", [_vm._v(_vm._s(_vm.currentDocent.item.desc))]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.currentTab === "film"
                ? [
                    _c("div", { staticClass: "viewer_wrap type_video" }, [
                      _c(
                        "div",
                        {
                          staticClass: "video_area",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c("video", {
                            ref: "video",
                            attrs: {
                              src: _vm.makingInfos[0].resUrl,
                              id: "makingFilm",
                              "aria-label":
                                "makingFilm for " + _vm.editionInfos.title,
                            },
                            on: {
                              ended: function ($event) {
                                _vm.isPlay = false
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "controls_area type_start" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn type_ic player",
                            on: { click: _vm.makingfilmPlayPause },
                          },
                          [
                            !_vm.isPlay
                              ? _c("IcPlayL", {
                                  staticClass: "ic_play",
                                  attrs: { isPlayer: _vm.isPlayer },
                                  on: {
                                    "update:isPlayer": function ($event) {
                                      _vm.isPlayer = $event
                                    },
                                    "update:is-player": function ($event) {
                                      _vm.isPlayer = $event
                                    },
                                  },
                                })
                              : _c("IcPauseL", { staticClass: "ic_pause" }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _c("QrModal", {
        attrs: {
          "modal-data": _vm.currentQrInfo,
          showQrModal: _vm.showQrModal,
          editionId: _vm.editionId,
          artworkId: _vm.artworkId,
          "modal-id": _vm.modalId,
          page: "Marketplace",
        },
        on: { close: _vm.close },
      }),
      _c("SetPriceModal", {
        attrs: {
          showPriceModal: _vm.showPriceModal,
          editionId: _vm.itemEditionId,
          artworkId: _vm.artworkId,
          "modal-size": "m",
        },
        on: { close: _vm.close },
      }),
      _c("SetEthModal", {
        attrs: {
          showEthModal: _vm.showEthModal,
          editionId: _vm.itemEditionId,
          artworkId: _vm.artworkId,
          "modal-size": "m",
        },
        on: { close: _vm.close },
      }),
      _c("EthLoading", {
        attrs: { showEthLoading: _vm.showEthLoading, "modal-size": "m" },
        on: { close: _vm.close },
      }),
      _c("ConnectWalletModal", {
        attrs: { showWallet: _vm.showWallet },
        on: { close: _vm.close },
      }),
      _c("InsufficientBalance", {
        attrs: {
          showUsdc: _vm.showUsdc,
          associatedSign: _vm.associatedSign,
          "modal-data": _vm.usdcModalInfo,
        },
        on: { close: _vm.close },
      }),
      _c("UpdateInfoModal", {
        ref: "UpdateInfoModal",
        attrs: {
          price: _vm.showInfoEditionPrice,
          priceToEthUsd: _vm.showInfoEditionPriceToEthUsd,
          selectId: _vm.selectEditionId,
          bcNwTp: _vm.selectbcNwtp,
        },
        on: { zipcodeSave: _vm.zipcodeSave },
      }),
      _c("PurchaseProgressModal", {
        attrs: { showEthProgress: _vm.showEthProgress },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v(" Contract "),
      _c("br", { staticClass: "dv_mob" }),
      _vm._v(" Address "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("Estimated "),
      _c("br", { staticClass: "dv_mob" }),
      _vm._v("Tax"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "item" }, [_vm._v("Editions")]),
        _c("th", { staticClass: "own" }, [_vm._v("Owned by")]),
        _c("th", { staticClass: "price" }, [_vm._v("Last Sale Price")]),
        _c("th", { staticClass: "time" }, [_vm._v("Last Sale Time")]),
        _c("th", { staticClass: "current" }, [_vm._v("Current Price")]),
        _c("th", { staticClass: "button" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "unit" }, [_vm._v("Ξ"), _c("br")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "button" }, [
      _c(
        "button",
        { staticClass: "btn strong w_xs h_m", attrs: { disabled: "" } },
        [_vm._v(" Not for Sale ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }