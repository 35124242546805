var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_list_page other_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("other_page")]),
      _c("div", { staticClass: "set_inner" }, [
        _c("section", { staticClass: "other_sec" }, [
          _c("div", { staticClass: "other_sec_head" }, [
            _c("div", { staticClass: "profile_group" }, [
              _c("div", { staticClass: "profile_info" }, [
                _vm.avatarUrl !== "" && _vm.avatarUrl
                  ? _c("span", {
                      staticClass: "profile",
                      style: `background-image: url('${_vm.avatarUrl}')`,
                    })
                  : _c(
                      "span",
                      { staticClass: "profile none" },
                      [
                        _c("IcProfile", {
                          staticClass: "ic_profile",
                          attrs: { width: "113", height: "113" },
                        }),
                      ],
                      1
                    ),
                _c("h2", { staticClass: "ttl" }, [
                  _vm._v(_vm._s(_vm.userInfo.name)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "follow_group" }, [
              _c("ul", { staticClass: "follow_area" }, [
                _c("li", { staticClass: "following" }, [
                  _c(
                    "button",
                    {
                      ref: "following",
                      attrs: { role: "status" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("FollowModal", false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("numberFormatter")(
                              +_vm.userInfo.following,
                              2
                            )
                          ) +
                          " "
                      ),
                      _c("span", [_vm._v("Following")]),
                    ]
                  ),
                ]),
                _c("li", { staticClass: "followers" }, [
                  _c(
                    "button",
                    {
                      ref: "followers",
                      attrs: { role: "status" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("FollowModal", true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("numberFormatter")(
                              +_vm.userInfo.followers,
                              2
                            )
                          ) +
                          " "
                      ),
                      _c("span", [_vm._v("Followers")]),
                    ]
                  ),
                ]),
              ]),
              _vm.mbrNo !== null && _vm.myMbrNo !== _vm.mbrNo
                ? _c("div", { staticClass: "btn_area" }, [
                    _c(
                      "button",
                      {
                        ref: "follow",
                        staticClass: "btn basic w_m h_l",
                        class: { is_active: _vm.userInfo.followYn === "Y" },
                        on: {
                          click: function ($event) {
                            return _vm.follow()
                          },
                        },
                      },
                      [
                        _vm.userInfo.followYn === "N"
                          ? _c("IcMember", {
                              attrs: { "stroke-color": "#000000" },
                            })
                          : _vm._e(),
                        _vm.userInfo.followYn === "Y"
                          ? _c("IcCheck")
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.userInfo.followYn === "Y"
                                ? "Following"
                                : "Follow"
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.ownedEditions && _vm.ownedEditions.length
            ? _c(
                "div",
                { staticClass: "other_sec_body" },
                [
                  _c("div", { staticClass: "data_length_area" }, [
                    _c("h2", { staticClass: "ttl" }, [
                      _c("em", [_vm._v(_vm._s(_vm.ownedEditions.length))]),
                      _vm._v(" Owned "),
                    ]),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "grid grid_15 ib edit_list" },
                    _vm._l(_vm.ownedEditions, function (item) {
                      return _c(
                        "li",
                        { key: item.id, staticClass: "col col_3 edit_node" },
                        [
                          _c("ItemCardEdit", {
                            attrs: {
                              itemData: item,
                              userType: _vm.userType,
                              viewType: "other",
                              mbrNo: _vm.myMbrNo,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("InfinityScroll", { on: { scroll: _vm.loadMore } }),
                ],
                1
              )
            : _c("div", { staticClass: "other_sec_body" }, [
                _vm._m(0),
                _c("div", { staticClass: "guide_ui no_data" }, [
                  _c("div", { staticClass: "img_area" }, [
                    _c("picture", [
                      _c("source", {
                        attrs: {
                          media: "(max-width: 600px)",
                          type: "image/png",
                          srcset: `${require("@/assets/img/img_mob_illust_nodata_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nodata_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nodata.png")}`,
                        },
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/img_illust_nodata.png"),
                          alt: "img_illust_nodata",
                        },
                      }),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.userInfo.name) +
                        " doesn’t have own artworks yet!!"
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn w_xl h_l strong",
                      staticStyle: { "margin-top": "20px" },
                      on: {
                        click: function ($event) {
                          return _vm.goMarketPlaceItemList()
                        },
                      },
                    },
                    [_vm._v(" Go to Marketplace ")]
                  ),
                ]),
              ]),
        ]),
      ]),
      _c("FollowList", {
        key: _vm.componentKey,
        ref: "FollowModal",
        attrs: { id: "followList", mbrNo: _vm.mbrNo },
        on: { follow: _vm.handleFollow, onSetCloseFocus: _vm.onSetCloseFocus },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data_length_area" }, [
      _c("h2", { staticClass: "ttl" }, [
        _c("em", [_vm._v("0")]),
        _vm._v(" Owned"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }