var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.bannerModal } },
    [
      _c(
        "div",
        {
          staticClass: "modal",
          staticStyle: { "max-width": "82rem" },
          attrs: { role: "dialog" },
        },
        [
          _c("div", { staticClass: "modal_head" }, [
            _c("h3", { staticClass: "modal_ttl" }, [
              _vm._v(_vm._s(_vm.subject)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "modal_body", staticStyle: { padding: "20px" } },
            [
              _c("div", { staticClass: "has_scroll_yy" }, [
                _c(
                  "p",
                  {
                    staticClass: "desc",
                    staticStyle: {
                      "text-align": "left",
                      "line-height": "30px",
                      "font-size": "1.8rem",
                    },
                  },
                  [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Dear LG Art Lab Community,"),
                    ]),
                    _c("br"),
                    _vm._v(
                      " We want to share an important update regarding LG Art Lab."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._m(0),
                    _c("br"),
                    _vm._v(
                      " As the NFT space continues to evolve, we believe it is the right time"
                    ),
                    _c("br"),
                    _vm._v(
                      " to shift our focus and explore new opportunities. "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Since launching, LG Art Lab has been dedicated to bridging the world of "
                    ),
                    _c("br"),
                    _vm._v(
                      "digital art with cutting-edge display technology, bringing NFT collections to"
                    ),
                    _c("br"),
                    _vm._v(
                      " a wider audience through our innovative platform. We have collaborated with visionary artists, introduced groundbreaking NFT drops, and provided"
                    ),
                    _c("br"),
                    _vm._v(
                      " a seamless experience for collectors to enjoy digital art on"
                    ),
                    _c("br"),
                    _vm._v(
                      " LG’s premium screens. Our commitment to fostering creativity"
                    ),
                    _c("br"),
                    _vm._v(
                      " and innovation in the NFT space has been at the heart of everything"
                    ),
                    _c("br"),
                    _vm._v(" we’ve done. "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" LG Art Lab will officially close on "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("June 17, 2025."),
                    ]),
                    _c("br"),
                    _vm._v(
                      " To ensure a smooth transition, please take note of the following:"
                    ),
                    _c("br"),
                    _vm._v("      - Final date for transactions: "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("March 10, 2025"),
                    ]),
                    _c("br"),
                    _vm._v(
                      "      - How to withdraw and transfer asset (there are two ways): "
                    ),
                    _c("br"),
                    _vm._v(
                      "          (1) LG Art Lab Team will automatically transfer the assets"
                    ),
                    _c("br"),
                    _vm._v(
                      "                that are currently in LG Art Lab Marketplace for re-sell to"
                    ),
                    _c("br"),
                    _vm._v("                collectors own wallets by "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("April 30, 2025."),
                    ]),
                    _c("br"),
                    _vm._v(
                      "         (2) Artists and collectors can manually transfer artworks that are"
                    ),
                    _c("br"),
                    _vm._v(
                      "                reside in LG Art Lab Marketplace for re-sell to their own wallets"
                    ),
                    _c("br"),
                    _vm._v(
                      "                or retract for re-selling in LG Art Lab Marketplace. "
                    ),
                    _c("br"),
                    _vm._v(
                      "                Please note that Etherium minted NFTs can be re-sold in"
                    ),
                    _c("br"),
                    _vm._v("                other platforms (e.g. Opensea)"),
                    _c("br"),
                    _c("img", {
                      attrs: { src: require("@/assets/img/popup.png") },
                    }),
                    _c("br"),
                    _c("br"),
                    _vm._v("      - Officially closing date: "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("June 17, 2025"),
                    ]),
                    _c("br"),
                    _vm._v("      - Customer support: available until "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("July 18, 2025"),
                    ]),
                    _c("br"),
                    _vm._v(
                      " We are incredibly grateful for the artists, collectors, and enthusiasts"
                    ),
                    _c("br"),
                    _vm._v(
                      " who have been part of this journey. Your support and creativity have made "
                    ),
                    _c("br"),
                    _vm._v(
                      "LG Art Lab a truly special place, and we sincerely appreciate the trust"
                    ),
                    _c("br"),
                    _vm._v(
                      " you’ve placed in us. While this chapter is coming to an end, our passion"
                    ),
                    _c("br"),
                    _vm._v(
                      " for digital art and innovation remains strong, and we look forward to seeing "
                    ),
                    _c("br"),
                    _vm._v("how the NFT space continues to grow and evolve."),
                    _c("br"),
                    _c("br"),
                    _vm._v(" Thank you for being part of LG Art Lab"),
                    _c("br"),
                    _vm._v(" With appreciation, "),
                    _c("br"),
                    _vm._v(" LG Art Lab Team"),
                    _c("br"),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "modal_foot" }, [
            _c("div", { staticClass: "btn_area" }, [
              _c(
                "button",
                { staticClass: "btn w_s h_m strong", on: { click: _vm.close } },
                [_vm._v(" " + _vm._s(_vm.btnTxt) + " ")]
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn type_ic close_modal",
              on: { click: _vm.close },
            },
            [_c("IcCloseM")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticStyle: { "font-weight": "bold", color: "#ff521f" } },
      [
        _vm._v(
          "After careful consideration, we have made the difficult decision"
        ),
        _c("br"),
        _vm._v(" to close the platform. "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }